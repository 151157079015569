import { useEffect, useState } from "react";
import { KnownSDK } from "@contentful/app-sdk";
import { createClient } from "contentful-management";
import { ContentfulManagementClients } from "../types";

export const useContentfulClients = ({ sdk }: { sdk: KnownSDK }) => {
  const [clients, setClients] = useState<ContentfulManagementClients | null>();

  useEffect(() => {
    (async () => {
      try {
        const client = createClient({ apiAdapter: sdk.cmaAdapter });
        const space = await client.getSpace(sdk.ids.space);
        const environment = await space.getEnvironment(sdk.ids.environment);

        setClients({
          client,
          space,
          environment,
        });
      } catch (error) {
        setClients(null);
      }
    })();
  }, [sdk]);

  return clients;
};
