import { Note } from "@contentful/f36-components";

const GeneralNote = ({ text }: { text: string }) => {
  return (
    <Note variant="primary" title="Conditional Fields">
      {text}
    </Note>
  );
};

export default GeneralNote;
