import { CodeBlock, a11yLight } from "react-code-blocks";
import { EditorInterfaces } from "../types";

const CONTENT_TYPE_FIELD_VALIDATION_DOC_LINK =
  "https://www.contentful.com/developers/docs/references/content-management-api/#/reference/content-types/content-type";

const codeText = `type ConditionalFieldMetadata = {
  // The id of the contentType you want to configure a conditional field on. Any
  // number of contentTypes can be added to the object
  [contentTypeId: string]: {
    // The id of the field using the \`conditional-fields\` app. Any number of
    // fields can be using the app on any given contentType.
    [conditionalFieldId: string]: {
      // The field whose value determines the validations for the conditional
      // field
      dependentFieldId: string;
      // an optional baseEditorInterfaceWidget, used by default if no
      // editorInterfaceWidget is included on the current Condition. If not
      // provided, the standard editorInterfaceWidget for the field type will be
      // used. See below for available implemented options
      baseEditorInterfaceWidget?: EditorInterfaceWidgetOptions;
      // The default conditions to apply if none of the values match. Note that
      // it will not be merged with the Condition for a values
      default?: Condition;
      // Each property in the values object corresponds to a value the
      // dependentField could take, with the associated conditions to apply in
      // that case
      values: {
        [value: string]: Condition;
      };
    };
  };
};

// Scoped down to the editor interfaces supported by the app
type EditorInterfaceWidgetOptions =
${Object.values(EditorInterfaces)
  .map((editorInterface) => `  | "${editorInterface}"`)
  .join("\n")};

type Condition = {
  // Prevents the field from being edited
  disabled?: boolean;
  // an alternative editorInterfaceWidget for this Condition, see below for
  // available implemented options
  editorInterfaceWidget?: EditorInterfaceWidgetOptions;
  // validations and itemValidations are the same as when constructing a
  // contentType field using the CMA SDK.
  validations?: ContentTypeFieldValidation[];
  itemValidations?: ContentTypeFieldValidation[];
  params?: {
    // Reference entity parameters
    showCreateEntityAction?: boolean;
    showLinkEntityAction?: boolean;
    bulkEditing?: boolean;
    viewType?: "card" | "link";
    // Boolean params
    trueLabel?: string;
    falseLabel?: string;
  };
};

`;

export const ExampleCodeBlock = () => (
  <code>
    <CodeBlock
      text={codeText}
      language="typescript"
      showLineNumbers={false}
      // showLineNumbers
      // startingLineNumber={startingLineNumber}
      theme={{ ...a11yLight }}
    />
    {`// Details on `}
    <a href={CONTENT_TYPE_FIELD_VALIDATION_DOC_LINK}>
      ContentTypeFieldValidation
    </a>
  </code>
);
