import { FieldExtensionSDK } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import GeneralNote from "../components/GeneralNote";
import GeneralWarning from "../components/GeneralWarning";
import LoadingIndicator from "../components/LoadingIndicator";
import { getConditionalFieldMetadata } from "../helpers/getConditionalFieldMetadata";
import { getFieldEditor } from "../helpers/getFieldEditor";
import { useAutoResizer } from "../hooks/useAutoResizer";
import { useCondition } from "../hooks/useCondition";
import { useContentfulClients } from "../hooks/useContentfulClients";
import { VALIDATOR_FIELD, useValidatorField } from "../hooks/useValidatorField";
import MiniNote from "../components/MiniNote";

const Field = () => {
  const sdk = useSDK<FieldExtensionSDK>();
  useAutoResizer(sdk.window);

  const clients = useContentfulClients({ sdk });
  const conditionalFieldMetadata = getConditionalFieldMetadata({
    sdk,
  });
  const { condition, baseEditorInterfaceWidget, onDisable } = useCondition({
    conditionalFieldMetadata,
    sdk,
  });

  const FieldEditor = getFieldEditor(sdk);

  const handleValidationUpdate = useValidatorField({ sdk });

  if (sdk.field.id === VALIDATOR_FIELD)
    return <GeneralNote text="Independently managed validator field" />;

  if (clients === undefined) return <LoadingIndicator />;

  if (clients === null)
    return (
      <GeneralWarning text="There was an error fetching Contentful clients" />
    );

  if (conditionalFieldMetadata === null)
    return (
      <GeneralWarning text="There was an error getting the installation parameter" />
    );

  if (typeof condition === "string") return <GeneralWarning text={condition} />;

  if (condition.disabled) {
    if (onDisable?.action === 'clear') sdk.field.setValue(undefined)
    if (onDisable?.action === 'set') sdk.field.setValue(onDisable.value)

    handleValidationUpdate(null);
    return <MiniNote text="Disabled" />;
  }

  return (
    <div style={{ marginRight: "1px" }}>
      <FieldEditor
        sdk={sdk}
        clients={clients}
        handleValidationUpdate={handleValidationUpdate}
        editorInterfaceWidget={baseEditorInterfaceWidget}
        // Spread in the condition
        {...condition}
      />
    </div>
  );
};

export default Field;
