import { FieldExtensionSDK } from "@contentful/app-sdk";
import { ContentFields } from "contentful-management";
import ArrayEntryField from "../components/ArrayEntryField/ArrayEntryField";
import ArraySymbolField from "../components/ArraySymbolField/ArraySymbolField";
import BooleanField from "../components/BooleanField/BooleanField";
import GeneralWarning from "../components/GeneralWarning";
import NumberField from "../components/NumberField/NumberField";
import RichTextField from "../components/RichTextField/RichTextField";
import SymbolField from "../components/SymbolField/SymbolField";
import { EditorComponent } from "../types";

export const getFieldEditor = (sdk: FieldExtensionSDK): EditorComponent => {
  const fieldDefinition = sdk.contentType.fields.find(
    (field) => field.id === sdk.field.id
  ) as ContentFields;

  if (fieldDefinition.type === "RichText") return RichTextField;

  if (fieldDefinition.type === "Symbol") return SymbolField;

  if (fieldDefinition.type === "Text") return UnimplementedWarning; // TODO - implement TextField

  if (fieldDefinition.type === "StructuredText") return UnimplementedWarning; // TODO - implement StructuredTextField

  if (fieldDefinition.type === "Integer") return NumberField;

  if (fieldDefinition.type === "Number") return NumberField;

  if (fieldDefinition.type === "Date") return UnimplementedWarning; // TODO - implement DateField

  if (fieldDefinition.type === "Location") return UnimplementedWarning; // TODO - implement LocationField

  if (fieldDefinition.type === "Boolean") return BooleanField;

  if (fieldDefinition.type === "Object") return UnimplementedWarning; // TODO - implement ObjectField

  if (fieldDefinition.type === "Link") {
    if (fieldDefinition.linkType === "Asset") return UnimplementedWarning; // TODO - implement LinkAssetField

    if (fieldDefinition.linkType === "Entry") return UnimplementedWarning; // TODO - implement LinkEntryField
  }

  if (fieldDefinition.type === "Array") {
    if (fieldDefinition?.items?.type === "Symbol") return ArraySymbolField;

    if (fieldDefinition?.items?.type === "Link") {
      if (fieldDefinition?.items?.linkType === "Asset")
        return UnimplementedWarning; // TODO - implement ArrayAssetField;

      if (fieldDefinition?.items?.linkType === "Entry") return ArrayEntryField;
    }
  }

  return UnknownFieldTypeWarning;
};

export const UnimplementedWarning = () => (
  <GeneralWarning text="The editor for a field of this type is not yet implemented!" />
);

export const UnknownFieldTypeWarning = () => (
  <GeneralWarning text="A field of this type is not known!" />
);
