import {
  TextInput,
  Flex,
  FormControl,
  HelpText,
} from "@contentful/f36-components";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { EditorProps } from "../../types";
import { ContentTypeFieldValidation } from "contentful-management";
import DnDPills from "./DnDPills";
import MultipleCheckbox from "./MultipleCheckbox";
import MultiSelect from "./MultiSelect";

const ArraySymbolField = ({
  sdk,
  validations,
  itemValidations,
  // TODO: implement manual control of widget
  editorInterfaceWidget,
  handleValidationUpdate,
}: EditorProps) => {
  const { max, min } =
    validations?.find((validation) => !!validation.size)?.size || {};
  const { max: itemsMax, min: itemsMin } =
    itemValidations?.find((validation) => !!validation.size)?.size || {};
  const itemsIn: ContentTypeFieldValidation["in"] = itemValidations?.find(
    (validation) => !!validation.in
  )?.in;
  const itemsProhibitRegexp: ContentTypeFieldValidation["regexp"] =
    itemValidations?.find(
      (validation) => !!validation.prohibitRegexp
    )?.prohibitRegexp;
  const itemsRegexp: ContentTypeFieldValidation["regexp"] =
    itemValidations?.find((validation) => !!validation.regexp)?.regexp;

  const [existingFieldValue, setExistingFieldValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");

  const [sizeErrorMessage, setSizeErrorMessage] = useState("");
  const [itemsSizeErrorMessage, setItemsSizeErrorMessage] = useState("");
  const [itemsInErrorMessage, setItemsInErrorMessage] = useState("");
  const [itemsRegexpErrorMessage, setItemsRegexpErrorMessage] = useState("");
  const [itemsProhibitRegexpErrorMessage, setItemsProhibitRegexpErrorMessage] =
    useState("");

  useEffect(() => {
    sdk.field.onValueChanged((value) => setExistingFieldValue(value || []));
  }, [itemsIn, sdk.field]);

  const validateSize = useCallback((): boolean => {
    const length = existingFieldValue?.length || 0;

    if (min !== undefined && length < min) {
      setSizeErrorMessage(
        `The field must have at least ${min} item${min > 1 ? "s" : ""}.`
      );
      return true;
    } else if (max !== undefined && length > max) {
      setSizeErrorMessage(
        `The field must have at most ${max} item${max > 1 ? "s" : ""}.`
      );
      return true;
    }

    setSizeErrorMessage("");
    return false;
  }, [existingFieldValue?.length, max, min]);

  const validateItemsSize = useCallback((): boolean => {
    const incorrectItems = existingFieldValue.filter(
      (item) =>
        (itemsMin !== undefined && item.length < itemsMin) ||
        (itemsMax !== undefined && item.length > itemsMax)
    );

    if (incorrectItems.length) {
      const minLengthPhrase = `the minimum length for items is ${itemsMin}`;
      const maxLengthPhrase = `the maximum length for items is ${itemsMax}`;
      const combinedPhrases = [minLengthPhrase, maxLengthPhrase].join(", and ");
      setItemsSizeErrorMessage(
        `The field must not contain: ${incorrectItems
          ?.map((item) => `"${item}"`)
          .join(", ")}, as ${combinedPhrases}.`
      );
      return true;
    }

    setItemsSizeErrorMessage("");
    return false;
  }, [existingFieldValue, itemsMax, itemsMin]);

  const validateItemsIn = useCallback((): boolean => {
    if (!itemsIn) {
      setItemsInErrorMessage("");
      return false;
    }

    const incorrectItems = existingFieldValue.filter(
      (item) => !itemsIn.includes(item)
    );
    if (incorrectItems.length) {
      const disallowedOptions = `The field must not contain: ${incorrectItems
        ?.map((item) => `"${item}"`)
        .join(", ")}.`
      const allowedOptions = `The values allowed are ${itemsIn
        ?.map((item) => `"${item}"`)
        .join(", ")}`
      setItemsInErrorMessage(`${disallowedOptions} ${!itemsIn ? allowedOptions : ''}`);
      return true;
    }

    setItemsInErrorMessage("");
    return false;
  }, [existingFieldValue, itemsIn]);

  const validateItemsRegexp = useCallback((): boolean => {
    if (!itemsRegexp) {
      setItemsRegexpErrorMessage("");
      return false;
    }

    const { pattern, flags } = itemsRegexp;
    const regex = new RegExp(pattern, flags);

    const incorrectItems = existingFieldValue.filter(
      (item) => !regex.test(item)
    );
    if (incorrectItems.length) {
      setItemsRegexpErrorMessage(
        `The field must not contain: ${incorrectItems
          ?.map((item) => `"${item}"`)
          .join(", ")} as they do not match the pattern \\${pattern}\\${flags}`
      );
      return true;
    }

    setItemsRegexpErrorMessage("");
    return false;
  }, [existingFieldValue, itemsRegexp]);

  const validateItemsProhibitRegexp = useCallback((): boolean => {
    if (!itemsProhibitRegexp) {
      setItemsProhibitRegexpErrorMessage("");
      return false;
    }

    const { pattern, flags } = itemsProhibitRegexp;
    const regex = new RegExp(pattern, flags);

    const incorrectItems = existingFieldValue.filter((item) =>
      regex.test(item)
    );
    if (incorrectItems.length) {
      setItemsProhibitRegexpErrorMessage(
        `The field must not contain: ${incorrectItems
          ?.map((item) => `"${item}"`)
          .join(", ")} as the pattern \\${pattern}\\${flags} is prohibited`
      );
      return true;
    }

    setItemsProhibitRegexpErrorMessage("");
    return false;
  }, [existingFieldValue, itemsProhibitRegexp]);

  const validate = useCallback(async () => {
    const sizeInvalid = validateSize();
    const itemsSizeInvalid = validateItemsSize();
    const itemsInInvalid = validateItemsIn();
    const itemsRegexpInvalid = validateItemsRegexp();
    const itemsProhibitRegexpInvalid = validateItemsProhibitRegexp();

    sdk.field.setInvalid(
      sizeInvalid ||
        itemsSizeInvalid ||
        itemsInInvalid ||
        itemsRegexpInvalid ||
        itemsProhibitRegexpInvalid
    );

    if (handleValidationUpdate)
      handleValidationUpdate({
        sizeInvalid,
        itemsSizeInvalid,
        itemsInInvalid,
        itemsRegexpInvalid,
        itemsProhibitRegexpInvalid,
      });
  }, [
    validateSize,
    validateItemsSize,
    validateItemsIn,
    validateItemsRegexp,
    validateItemsProhibitRegexp,
    sdk.field,
    handleValidationUpdate,
  ]);

  useEffect(() => {
    validate();
  }, [
    validateSize,
    validateItemsSize,
    validateItemsIn,
    validateItemsRegexp,
    validateItemsProhibitRegexp,
    validate,
  ]);

  const handleInputOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.target.value);
  };
  const handleInputOnKeyDown = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.code !== "Enter" || !inputValue) return;
    addValue(inputValue);
    setInputValue("");
  };
  const addValue = (value: string) => {
    sdk.field.setValue([...existingFieldValue, value]);
  };
  const handleSetItems = (items: string[]) => {
    sdk.field.setValue(items);
  };

  const handleCheckboxToggle = (value: string) => {
    if (existingFieldValue.includes(value)) {
      sdk.field.setValue(existingFieldValue.filter(item => item !== value));
    } else {
      sdk.field.setValue([...existingFieldValue, value]);
    }
  }

  const shouldUseFreeText = !itemsIn;
  const shouldUseMultipleCheckbox = itemsIn && itemsIn.length <= 5
  const shouldUseMultiSelect = itemsIn && itemsIn.length > 5

  return (
    <>
      {shouldUseFreeText && <Flex gap="16px" flexDirection="column">
        <TextInput
          type="text"
          value={inputValue}
          onChange={handleInputOnChange}
          onKeyDown={handleInputOnKeyDown}
        />
        {!!existingFieldValue.length && <DnDPills items={existingFieldValue} setItems={handleSetItems} />}
        {/* TODO: add dynamic helptext */}
        <HelpText>Type and press "enter" to add new items</HelpText>
      </Flex>}

      {shouldUseMultipleCheckbox && <MultipleCheckbox
        options={itemsIn.map(item => item.toString())}
        selectedOptions={existingFieldValue}
        noOptionsWarning="An `in` validation must be added for the checkboxes interface to work."
        onSelect={handleCheckboxToggle}
      />}

      {shouldUseMultiSelect && <MultiSelect
        options={itemsIn.map(item => item.toString())}
        selectedOptions={existingFieldValue}
        noOptionsWarning="An `in` validation must be added for the multi select interface to work."
        onSelect={handleSetItems}
      />}

      {sizeErrorMessage && (
        <FormControl.ValidationMessage>
          {sizeErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {itemsSizeErrorMessage && (
        <FormControl.ValidationMessage>
          {itemsSizeErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {itemsInErrorMessage && (
        <FormControl.ValidationMessage>
          {itemsInErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {itemsRegexpErrorMessage && (
        <FormControl.ValidationMessage>
          {itemsRegexpErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {itemsProhibitRegexpErrorMessage && (
        <FormControl.ValidationMessage>
          {itemsProhibitRegexpErrorMessage}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default ArraySymbolField;

/**
 * An array symbol field with all possible standard validations looks like:
 *
 * const modelField = {
 *   id: "arraySymbol",
 *   name: "Array Symbol",
 *   type: "Array",
 *   localized: false,
 *   required: false,
 *   disabled: false,
 *   omitted: false,
 *
 *   validations: [
 *     {
 *       size: {
 *         min: 0,
 *         max: 5,
 *       },
 *     },
 *   ],
 *
 *   items: {
 *     type: "Symbol",
 *     validations: [
 *       {
 *         size: {
 *           min: 0,
 *           max: 10,
 *         },
 *       },
 *       {
 *         regexp: {
 *           pattern: "^foo",
 *           flags: null,
 *         },
 *       },
 *       {
 *         prohibitRegexp: {
 *           pattern: "baz$",
 *           flags: null,
 *         },
 *       },
 *       {
 *         in: [
 *           "foo1",
 *           "foo2",
 *           "foo3",
 *           "foo4",
 *           "foo5",
 *           "1baz",
 *           "2baz",
 *           "3baz",
 *           "4baz",
 *           "5baz",
 *           "fooverylong",
 *         ],
 *       },
 *     ],
 *   },
 * };
 */
