import { FieldExtensionSDK } from "@contentful/app-sdk";
import { Box, FormControl } from "@contentful/f36-components";
import {
  CombinedLinkActions,
  MultipleEntryReferenceEditor,
} from "@contentful/field-editor-reference";
import { ViewType } from "@contentful/field-editor-reference/dist/types";
import { EntryProps } from "contentful-management";
import { ReactNode } from "react";

/**
 * As the internals of the MultipleEntryReferenceEditor are unknown, it is very
 * difficult to provide a suitable mocked sdk for it to use. This component's
 * purpose is to encapsulate the MultipleEntryReferenceEditor as closely as
 * possible, so that it can be appropriately mocked in the ArrayEntryField
 * tests while testing as much as possible.
 */
const ContentfulMultipleEntryReferenceEditor = ({
  sdk,
  contentTypeIds,
  onSelectEntries,
  onAction,
  isFull,
  viewType,
  showCreateEntityAction,
  showLinkEntityAction,
  bulkEditing,
}: {
  sdk: FieldExtensionSDK;
  contentTypeIds?: string[];
  onSelectEntries: (entryIds: string[]) => Promise<void>;
  onAction: (() => void) | (() => Promise<void>);
  isFull: boolean;
  viewType?: ViewType;
  showCreateEntityAction?: boolean;
  showLinkEntityAction?: boolean;
  bulkEditing?: boolean;
}) => (
  <MultipleEntryReferenceEditor
    renderCustomActions={(props) => {
      const contentTypes = contentTypeIds?.length
        ? props.contentTypes?.filter((contentType) =>
            contentTypeIds?.includes(contentType.sys.id)
          )
        : props.contentTypes;

      return (
        <CombinedLinkActions
          {...props}
          {...(contentTypes ? { contentTypes } : {})}
          isFull={isFull}
          onLinkExisting={async () => {
            const selectedEntries =
              await sdk.dialogs.selectMultipleEntries<EntryProps>({
                locale: sdk.field.locale,
                contentTypes: contentTypeIds,
              });

            if (selectedEntries)
              await onSelectEntries(
                selectedEntries.map((entry) => entry.sys.id)
              );
          }}
        />
      );
    }}
    renderCustomCard={(props, _, renderDefaultCard) => {
      const contentTypeId = props.contentType?.sys.id;

      const isInvalidContentType =
        contentTypeIds &&
        contentTypeId &&
        !contentTypeIds.includes(contentTypeId);

      return (
        <>
          <Box
            style={
              isInvalidContentType
                ? {
                    border: "1px solid rgb(189, 0, 42)",
                    borderRadius: "6px",
                  }
                : {}
            }
          >
            {/* 
              Having to cast to ReactNode as
              TS2322: Type 'ReactElement<any, string | JSXElementConstructor<any>>' is not assignable to type 'ReactNode'.
              Property 'children' is missing in type 'ReactElement<any, string | JSXElementConstructor<any>>' but required in type 'ReactPortal'.
            */}
            {renderDefaultCard(props) as ReactNode}
          </Box>
          {isInvalidContentType && (
            <FormControl.ValidationMessage>
              This entry is of an invalid content type
            </FormControl.ValidationMessage>
          )}
        </>
      );
    }}
    sdk={sdk}
    isInitiallyDisabled={false}
    hasCardEditActions={false}
    viewType={viewType || "link"}
    onAction={onAction}
    parameters={{
      instance: {
        showCreateEntityAction,
        showLinkEntityAction,
        bulkEditing,
      },
    }}
  />
);

export default ContentfulMultipleEntryReferenceEditor;
