import { FormControl } from "@contentful/f36-components";
import { useCallback, useEffect, useState } from "react";
import { EditorProps } from "../../types";
import { NumberEditor as ContentfulNumberEditorField } from "@contentful/field-editor-number";

const NumberField = ({
  validations,
  sdk,
  handleValidationUpdate,
}: EditorProps) => {
  const { max, min } =
    validations?.find((validation) => !!validation.size)?.size ||
    validations?.find((validation) => !!validation.range)?.range ||
    {};
  const predefinedValues = validations?.find(
    (validation) => !!validation.in
  )?.in;

  const [existingFieldValue, setExistingFieldValue] = useState<number>();

  const [sizeErrorMessage, setSizeErrorMessage] = useState("");
  const [predefinedValueErrorMessage, setPredefinedValueErrorMessage] =
  useState("");

  useEffect(() => {
    sdk.field.onValueChanged(setExistingFieldValue);
  }, [predefinedValues, sdk.field]);

  const validateSize = useCallback((): boolean => {
    if (typeof existingFieldValue !== 'number') return false

    if (min !== undefined && existingFieldValue < min) {
      setSizeErrorMessage(`Please enter a number no less than ${min}.`);
      return true;
    } else if (max !== undefined && existingFieldValue > max) {
      setSizeErrorMessage(`Please enter a number no greater than ${max}.`);
      return true;
    }
    
    setSizeErrorMessage("");
    return false;
  }, [existingFieldValue, max, min]);

  const validatePredefinedValues = useCallback((): boolean => {
    if (typeof existingFieldValue !== 'number') return false

    if (
      predefinedValues !== undefined &&
      !predefinedValues.includes(existingFieldValue)
    ) {
      setPredefinedValueErrorMessage(
        `Must be one of [ ${predefinedValues.join(
          ", "
        )} ]. Any invalid options need to be removed.`
      );
      return true;
    }

    setPredefinedValueErrorMessage("")
    return false;
  }, [existingFieldValue, predefinedValues]);

  const validate = useCallback(() => {
    const sizeInvalid = validateSize();
    const predefinedValueInvalid = validatePredefinedValues();

    sdk.field.setInvalid(sizeInvalid || predefinedValueInvalid);

    if (handleValidationUpdate)
      handleValidationUpdate({
        sizeInvalid,
        predefinedValueInvalid,
      });
  }, [
    validateSize,
    validatePredefinedValues,
    sdk.field,
    handleValidationUpdate,
  ]);

  useEffect(() => {
    validate();
  }, [sdk.field, validate, validateSize, validatePredefinedValues]);

  return (
    <>
      <ContentfulNumberEditorField field={sdk.field} isInitiallyDisabled />
      {sizeErrorMessage && (
        <FormControl.ValidationMessage>
          {sizeErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {predefinedValueErrorMessage && (
        <FormControl.ValidationMessage>
          {predefinedValueErrorMessage}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default NumberField;

/**
"numberConditionExample": {
  "number": {
    "dependentFieldId": "variant",
    "values": {
      "disable": {
        "disabled": true
      },
      "between_1_10": {
        "validations": [
          {
            "size": {
              "min": 1,
              "max": 10
            }
          }
        ]
      },
      "specified_value_8": {
        "validations": [
          {
            "in": [
              8
            ]
          }
        ]
      },
      "greater_or_equal_than_5": {
        "validations": [
          {
            "range": {
              "min": 5
            }
          }
        ]
      },
      "less_or_equal_than_2": {
        "validations": [
          {
            "range": {
              "max": 2
            }
          }
        ]
      }
    }
  },
  "decimalNumber": {
    "dependentFieldId": "variant",
        "values": {
      "disable": {
        "disabled": true
      },
      "between_1_10": {
        "validations": [
          {
            "size": {
              "min": 1,
              "max": 10
            }
          }
        ]
      },
      "specified_value_8": {
        "validations": [
          {
            "in": [
              8
            ]
          }
        ]
      },
      "greater_or_equal_than_5": {
        "validations": [
          {
            "range": {
              "min": 5
            }
          }
        ]
      },
      "less_or_equal_than_2": {
        "validations": [
          {
            "range": {
              "max": 2
            }
          }
        ]
      }
    }
  }
}
**/
