import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle, Flex, Pill } from "@contentful/f36-components";

type DraggablePillProps = {
  value: string;
  onClose: () => void;
};

const DraggablePill = ({ value, onClose }: DraggablePillProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: value,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Pill
      dragHandleComponent={
        <DragHandle
          label="Reorder item"
          variant="transparent"
          {...attributes}
          {...listeners}
        />
      }
      isDraggable
      label={value}
      ref={setNodeRef}
      style={style}
      onClose={onClose}
    />
  );
};

type DnDPillsProps = {
  items: string[];
  setItems: (items: string[]) => void;
};

const DnDPills = ({ items, setItems }: DnDPillsProps) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over) return;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(String(active.id));
      const newIndex = items.indexOf(String(over.id));

      setItems(arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <Flex gap="spacingS" flexWrap="wrap">
          {items.map((item, index) => (
            <DraggablePill
              value={item}
              onClose={() => handleRemove(index)}
              key={index}
            />
          ))}
        </Flex>
      </SortableContext>
    </DndContext>
  );
};

export default DnDPills;
