import { Note } from "@contentful/f36-components";

const GeneralWarning = ({ text }: { text: string }) => {
  return (
    <Note variant="warning" title="Conditional Fields">
      {text}
    </Note>
  );
};

export default GeneralWarning;
