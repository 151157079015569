import { FormControl } from "@contentful/f36-components";
import { RichTextEditor } from "@contentful/field-editor-rich-text";
import {
  BLOCKS,
  Block,
  Document,
  INLINES,
  Inline,
  Text,
} from "@contentful/rich-text-types";
import { Entry } from "contentful-management";
import { useCallback, useEffect, useState } from "react";
import { EditorProps } from "../../types";

// FieldAppSdk is just a reassignment of FieldExtensionSDK, but isn't exported
// any more, so having to do this
type FieldAppSdk = Parameters<typeof RichTextEditor>[0]["sdk"];

const RichTextField = ({
  sdk,
  validations,
  clients,
  handleValidationUpdate,
}: EditorProps) => {
  const [embedTypeIds, setEmbedTypeIds] = useState<EmbedTypeId[]>([]);
  const [embeddedEntries, setEmbeddedEntries] = useState<Entry[]>([]);

  useEffect(() => {
    sdk.field.onValueChanged((value: Document) => {
      setEmbedTypeIds(parseDoc(value));
    });
  }, [sdk.field]);

  const nodesValidation =
    validations?.find((validation) => !!validation.nodes)?.nodes || {};

  const embeddedEntryBlockContentTypeIds = nodesValidation[
    BLOCKS.EMBEDDED_ENTRY
  ]?.find((validation) => !!validation.linkContentType)?.linkContentType;
  const embeddedEntryInlineContentTypeIds = nodesValidation[
    INLINES.EMBEDDED_ENTRY
  ]?.find((validation) => !!validation.linkContentType)?.linkContentType;
  const entryHyperlinkContentTypeIds = nodesValidation[
    INLINES.ENTRY_HYPERLINK
  ]?.find((validation) => !!validation.linkContentType)?.linkContentType;

  const [embeddedEntryBlockError, setEmbeddedEntryBlockError] = useState("");
  const [embeddedEntryInlineError, setEmbeddedEntryInlineError] = useState("");
  const [entryHyperlinkEntryError, setEntryHyperlinkEntryError] = useState("");

  useEffect(() => {
    let isMounted = true;

    clients.environment
      .getEntries({
        "sys.id[in]": embedTypeIds.map((embedTypeId) => embedTypeId.id).join(),
      })
      .then((entries) => {
        if (isMounted) {
          setEmbeddedEntries(entries.items);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [clients.environment, embedTypeIds]);

  const validate = useCallback(() => {
    clearHighlights();

    const invalidEmbeddedEntryBlockError =
      determineInvalidContentTypeIdsOfEmbedType({
        type: BLOCKS.EMBEDDED_ENTRY,
        embedTypeIds,
        validContentTypeIds: embeddedEntryBlockContentTypeIds,
        entries: embeddedEntries,
      });

    const invalidEmbeddedEntryInlineError =
      determineInvalidContentTypeIdsOfEmbedType({
        type: INLINES.EMBEDDED_ENTRY,
        embedTypeIds,
        validContentTypeIds: embeddedEntryInlineContentTypeIds,
        entries: embeddedEntries,
      });

    const invalidEntryHyperlinkError =
      determineInvalidContentTypeIdsOfEmbedType({
        type: INLINES.ENTRY_HYPERLINK,
        embedTypeIds,
        validContentTypeIds: entryHyperlinkContentTypeIds,
        entries: embeddedEntries,
      });

    return {
      invalidEmbeddedEntryBlockError,
      invalidEmbeddedEntryInlineError,
      invalidEntryHyperlinkError,
    };
  }, [
    embedTypeIds,
    embeddedEntries,
    embeddedEntryBlockContentTypeIds,
    embeddedEntryInlineContentTypeIds,
    entryHyperlinkContentTypeIds,
  ]);

  useEffect(() => {
    const {
      invalidEmbeddedEntryBlockError,
      invalidEmbeddedEntryInlineError,
      invalidEntryHyperlinkError,
    } = validate();

    sdk.field.setInvalid(
      Boolean(
        invalidEmbeddedEntryBlockError ||
          invalidEmbeddedEntryInlineError ||
          invalidEntryHyperlinkError
      )
    );

    setEmbeddedEntryBlockError(invalidEmbeddedEntryBlockError);
    setEmbeddedEntryInlineError(invalidEmbeddedEntryInlineError);
    setEntryHyperlinkEntryError(invalidEntryHyperlinkError);

    if (handleValidationUpdate)
      handleValidationUpdate({
        invalidEmbeddedEntryBlock: !!invalidEmbeddedEntryBlockError,
        invalidEmbeddedEntryInline: !!invalidEmbeddedEntryInlineError,
        invalidEntryHyperlink: !!invalidEntryHyperlinkError,
      });
  }, [handleValidationUpdate, sdk.field, validate]);

  return (
    <>
      <RichTextEditor
        sdk={sdk as unknown as FieldAppSdk}
        isInitiallyDisabled={false}
      />
      {embeddedEntryBlockError && (
        <FormControl.ValidationMessage>
          {embeddedEntryBlockError}
        </FormControl.ValidationMessage>
      )}
      {embeddedEntryInlineError && (
        <FormControl.ValidationMessage>
          {embeddedEntryInlineError}
        </FormControl.ValidationMessage>
      )}
      {entryHyperlinkEntryError && (
        <FormControl.ValidationMessage>
          {entryHyperlinkEntryError}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default RichTextField;

type ParseDocArg = Document | Block | Inline | Text | undefined;
const isDocument = (node: ParseDocArg): node is Document => {
  return node?.nodeType === "document";
};
const isBlock = (node: ParseDocArg): node is Block => {
  return Object.values(BLOCKS).includes(node?.nodeType as BLOCKS);
};
const isInline = (node: ParseDocArg): node is Inline => {
  return Object.values(INLINES).includes(node?.nodeType as INLINES);
};

type EmbedTypeId = {
  type:
    | BLOCKS.EMBEDDED_ENTRY
    | INLINES.EMBEDDED_ENTRY
    | INLINES.ENTRY_HYPERLINK;
  id: string;
};

const parseDoc = (doc: ParseDocArg): EmbedTypeId[] => {
  if (isBlock(doc)) {
    if (doc.nodeType === BLOCKS.EMBEDDED_ENTRY) {
      return [{ type: BLOCKS.EMBEDDED_ENTRY, id: doc.data.target.sys.id }];
    }
    if (doc.content) {
      return doc.content.flatMap(parseDoc);
    }
  }

  if (isInline(doc)) {
    if (doc.nodeType === INLINES.EMBEDDED_ENTRY) {
      return [{ type: INLINES.EMBEDDED_ENTRY, id: doc.data.target.sys.id }];
    }
    if (doc.nodeType === INLINES.ENTRY_HYPERLINK) {
      return [{ type: INLINES.ENTRY_HYPERLINK, id: doc.data.target.sys.id }];
    }
  }

  if (isDocument(doc)) {
    return doc.content.flatMap(parseDoc);
  }

  return [];
};

const determineInvalidContentTypeIdsOfEmbedType = ({
  type,
  embedTypeIds,
  validContentTypeIds,
  entries,
}: {
  type:
    | BLOCKS.EMBEDDED_ENTRY
    | INLINES.EMBEDDED_ENTRY
    | INLINES.ENTRY_HYPERLINK;
  embedTypeIds: EmbedTypeId[];
  validContentTypeIds?: string[];
  entries: Entry[];
}) => {
  if (!validContentTypeIds) return "";

  const embedIdsOfType = embedTypeIds
    .filter((embedTypeId) => embedTypeId.type === type)
    .map((embedTypeId) => embedTypeId.id);

  const invalidEmbeddedEntries = entries.filter(
    (entry) =>
      embedIdsOfType.includes(entry.sys.id) &&
      !validContentTypeIds.includes(entry.sys.contentType.sys.id)
  );

  addHighlights(type, invalidEmbeddedEntries);

  const invalidContentTypeIds = Array.from(
    new Set(invalidEmbeddedEntries.map((entry) => entry.sys.contentType.sys.id))
  );

  if (!invalidContentTypeIds.length) return "";

  const entryPluralisation =
    invalidContentTypeIds.length > 1 ? "entries" : "entry";
  const contentTypePluralisation =
    invalidContentTypeIds.length > 1 ? "contentTypes" : "contentType";
  const invalidContentTypeList = invalidContentTypeIds
    .map((contentTypeId) => `"${contentTypeId}"`)
    .join(", ");
  const validContentTypeList = validContentTypeIds
    .map((contentTypeId: string) => `"${contentTypeId}"`)
    .join(", ");
  const acceptableEntriesPhrase = validContentTypeIds.length
    ? `Only ${validContentTypeList} entries are allowed.`
    : "No content types are allowed.";

  return `Invalid ${type} ${entryPluralisation} of ${contentTypePluralisation} ${invalidContentTypeList}. ${acceptableEntriesPhrase}`;
};

const clearHighlights = () => {
  const highlightedEmbeds = document.querySelectorAll(
    '[data-conditional-fields-app="true"]'
  );
  highlightedEmbeds.forEach((embed) => {
    embed.removeAttribute("style");
    embed.removeAttribute("data-conditional-fields-app");
  });
};

const addHighlights = (
  type:
    | BLOCKS.EMBEDDED_ENTRY
    | INLINES.EMBEDDED_ENTRY
    | INLINES.ENTRY_HYPERLINK,
  invalidEmbeddedEntries: Entry[]
) => {
  const attribute = {
    [BLOCKS.EMBEDDED_ENTRY]: "data-entity-id",
    [INLINES.EMBEDDED_ENTRY]: "data-embedded-entity-inline-id",
    [INLINES.ENTRY_HYPERLINK]: "data-link-id",
  }[type];

  invalidEmbeddedEntries.forEach((entry) => {
    const embeds = document.querySelectorAll(
      `[${attribute}="${entry.sys.id}"]`
    );
    embeds.forEach((embed) => {
      embed.setAttribute(
        "style",
        "border: 3px solid rgb(189, 0, 42) !important; border-radius: 6px;"
      );
      embed.setAttribute("data-conditional-fields-app", "true");
    });
  });
};

/*
 * A RichText field with all possible standard validations looks like:
 *
 * However, we are not doing sizes to begin with, just contentTypes embedded
 *
 * const modelField = {
 *   id: "richText",
 *   name: "Rich Text",
 *   type: "RichText",
 *   localized: false,
 *   required: false,
 *   disabled: false,
 *   omitted: false,
 *
 *   validations: [
 *     {
 *       enabledMarks: [
 *         "bold",
 *         "italic",
 *         "underline",
 *         "code",
 *         "superscript",
 *         "subscript",
 *       ],
 *     },
 *     {
 *       enabledNodeTypes: [
 *         "heading-1",
 *         "heading-2",
 *         "heading-3",
 *         "heading-4",
 *         "heading-5",
 *         "heading-6",
 *         "ordered-list",
 *         "unordered-list",
 *         "hr",
 *         "blockquote",
 *         "embedded-entry-block",
 *         "embedded-asset-block",
 *         "table",
 *         "hyperlink",
 *         "entry-hyperlink",
 *         "asset-hyperlink",
 *         "embedded-entry-inline",
 *       ],
 *     },
 *     {
 *       nodes: {
 *         "asset-hyperlink": [
 *           {
 *             size: {
 *               min: 1,
 *               max: 5,
 *             },
 *             message: null,
 *           },
 *         ],
 *         "embedded-asset-block": [
 *           {
 *             size: {
 *               min: 1,
 *               max: 5,
 *             },
 *             message: null,
 *           },
 *         ],
 *         "embedded-entry-block": [
 *           {
 *             linkContentType: ["cat", "dog"],
 *             message: null,
 *           },
 *           {
 *             size: {
 *               min: 1,
 *               max: 5,
 *             },
 *             message: null,
 *           },
 *         ],
 *         "embedded-entry-inline": [
 *           {
 *             linkContentType: ["cat", "dog"],
 *             message: null,
 *           },
 *           {
 *             size: {
 *               min: 1,
 *               max: 5,
 *             },
 *             message: null,
 *           },
 *         ],
 *         "entry-hyperlink": [
 *           {
 *             linkContentType: ["cat", "dog"],
 *             message: null,
 *           },
 *           {
 *             size: {
 *               min: 1,
 *               max: 5,
 *             },
 *             message: null,
 *           },
 *         ],
 *       },
 *     },
 *   ],
 * };
 */
