import Select from "react-select";
import GeneralWarning from "../GeneralWarning";

type Option = string;
type MultiSelectProps = {
  onSelect: (value: Option[]) => void;
  options: Option[];
  selectedOptions: Option[];
  noOptionsWarning: string;
};

const MultiSelect = ({
  onSelect,
  options,
  selectedOptions,
  noOptionsWarning,
}: MultiSelectProps) => {
  if (!options.length) return <GeneralWarning text={noOptionsWarning} />

  const preparedOptions = options.map((option: Option) => ({
    value: option,
    label: option,
    isNotInOptions: false,
  }));
  const preparedSelectedOptions = selectedOptions.map((option: Option) => ({
    value: option,
    label: option,
    isNotInOptions: !options.includes(option),
  }));

  return (
    <Select
      value={preparedSelectedOptions}
      isMulti
      styles={{
        multiValue: (base, props) => ({
          ...base,
          border: props.data.isNotInOptions ? "2px solid red" : base.border,
        }),
      }}
      closeMenuOnSelect={false}
      options={preparedOptions}
      onChange={(selection) => onSelect(selection.map(({ value }) => value))}
    />
  );
};

export default MultiSelect;
