import { ExternalLinkIcon } from "@contentful/f36-icons";
import { FormControl, Stack, TextLink } from "@contentful/f36-components";
import { SingleLineEditor } from "@contentful/field-editor-single-line";
import { useCallback, useEffect, useState } from "react";
import { EditorProps } from "../../types";
import { ContentTypeFieldValidation } from "contentful-management";
import ContentfulRadioSymbolSelector from "./ContentfulRadioSymbolSelector";
import ContentfulDropdownSymbolSelector from "./ContentfulDropdownSymbolSelector";

const SymbolField = ({
  sdk,
  validations,
  clients,
  editorInterfaceWidget,
  handleValidationUpdate,
}: EditorProps) => {
  const { max, min } =
    validations?.find((validation) => !!validation.size)?.size || {};
  const unique =
    validations?.find((validation) => validation.unique)?.unique || false;
  const inArray: ContentTypeFieldValidation["in"] = validations?.find(
    (validation) => !!validation.in
  )?.in;
  const prohibitRegexp: ContentTypeFieldValidation["regexp"] =
    validations?.find(
      (validation) => !!validation.prohibitRegexp
    )?.prohibitRegexp;
  const regexp: ContentTypeFieldValidation["regexp"] = validations?.find(
    (validation) => !!validation.regexp
  )?.regexp;

  const [existingFieldValue, setExistingFieldValue] = useState<string>();

  const [sizeErrorMessage, setSizeErrorMessage] = useState("");
  const [uniqueErrorEntries, setUniqueErrorEntries] = useState<
    { id: string; name: string }[]
  >([]);
  const [inErrorMessage, setInErrorMessage] = useState("");
  const [regexpErrorMessage, setRegexpErrorMessage] = useState("");
  const [prohibitRegexpErrorMessage, setProhibitRegexpErrorMessage] =
    useState("");

  useEffect(() => {
    sdk.field.onValueChanged(setExistingFieldValue);
  }, [inArray, sdk.field]);

  const validateSize = useCallback((): boolean => {
    const length = existingFieldValue?.length || 0;

    if (min !== undefined && length < min) {
      setSizeErrorMessage(
        `The field must have at least ${min} character${min > 1 ? "s" : ""}.`
      );
      return true;
    } else if (max !== undefined && length > max) {
      setSizeErrorMessage(
        `The field must have at most ${max} character${max > 1 ? "s" : ""}.`
      );
      return true;
    }

    setSizeErrorMessage("");
    return false;
  }, [existingFieldValue?.length, max, min]);

  const validateUnique = useCallback(async (): Promise<boolean> => {
    if (!unique) {
      setUniqueErrorEntries([]);
      return false;
    }

    const entries = await clients.environment.getEntries({
      content_type: sdk.contentType.sys.id,
      [`fields.${sdk.field.id}`]: existingFieldValue,
      "sys.publishedAt[exists]": true,
      "sys.id[ne]": sdk.entry.getSys().id,
    });
    if (entries.items.length) {
      setUniqueErrorEntries(
        entries.items.map((entry) => ({
          id: entry.sys.id,
          name: entry.fields[sdk.contentType.displayField][sdk.locales.default],
        }))
      );
      return true;
    }

    setUniqueErrorEntries([]);
    return false;
  }, [
    clients.environment,
    existingFieldValue,
    sdk.contentType.displayField,
    sdk.contentType.sys.id,
    sdk.entry,
    sdk.field.id,
    sdk.locales.default,
    unique,
  ]);

  const validateIn = useCallback((): boolean => {
    if (!existingFieldValue || !inArray) {
      setInErrorMessage("");
      return false;
    }

    if (!inArray.includes(existingFieldValue)) {
      setInErrorMessage(
        `The field must be one of: ${inArray
          ?.map((item) => `"${item}"`)
          .join(", ")}`
      );
      return true;
    }

    setInErrorMessage("");
    return false;
  }, [existingFieldValue, inArray]);

  const validateRegexp = useCallback((): boolean => {
    if (typeof existingFieldValue !== "string" || !regexp) {
      setRegexpErrorMessage("");
      return false;
    }

    const { pattern, flags } = regexp;
    const matched = new RegExp(pattern, flags).test(existingFieldValue);

    if (!matched) {
      setRegexpErrorMessage(`The pattern \\${pattern}\\${flags} is required`);
      return true;
    }

    setRegexpErrorMessage("");
    return false;
  }, [existingFieldValue, regexp]);

  const validateProhibitRegexp = useCallback((): boolean => {
    if (typeof existingFieldValue !== "string" || !prohibitRegexp) {
      setProhibitRegexpErrorMessage("");
      return false;
    }

    const { pattern, flags } = prohibitRegexp;
    const matched = new RegExp(pattern, flags).test(existingFieldValue);

    if (matched) {
      setProhibitRegexpErrorMessage(
        `The pattern \\${pattern}\\${flags} is prohibited`
      );
      return true;
    }

    setProhibitRegexpErrorMessage("");
    return false;
  }, [existingFieldValue, prohibitRegexp]);

  const validate = useCallback(async () => {
    const sizeInvalid = validateSize();
    const uniqueInvalid = await validateUnique();
    const inInvalid = validateIn();
    const regexpInvalid = validateRegexp();
    const prohibitRegexpInvalid = validateProhibitRegexp();

    sdk.field.setInvalid(
      sizeInvalid ||
      uniqueInvalid ||
      inInvalid ||
      regexpInvalid ||
      prohibitRegexpInvalid
    );

    if (handleValidationUpdate)
      handleValidationUpdate({
        sizeInvalid,
        uniqueInvalid,
        inInvalid,
        regexpInvalid,
        prohibitRegexpInvalid,
      });
  }, [
    validateSize,
    validateUnique,
    validateIn,
    validateRegexp,
    validateProhibitRegexp,
    sdk.field,
    handleValidationUpdate,
  ]);

  useEffect(() => {
    validate();
  }, [
    validateSize,
    validateUnique,
    validateIn,
    validateRegexp,
    validateProhibitRegexp,
    validate,
  ]);

  const handleOnSelect = useCallback(
    (value: string) => {
      sdk.field.setValue(value);
    },
    [sdk.field]
  );

  const renderEditor = () => {
    switch (editorInterfaceWidget) {
      case "radio":
        return (
          <ContentfulRadioSymbolSelector
            onSelect={handleOnSelect}
            options={(inArray || []) as string[]}
            selectedOption={existingFieldValue || ""}
            noOptionsWarning="An `in` validation must be added for the radio interface to work."
          />
        );

      case "dropdown":
        return (
          <ContentfulDropdownSymbolSelector
            onSelect={handleOnSelect}
            options={(inArray || []) as string[]}
            selectedOption={existingFieldValue || ""}
            noOptionsWarning="An `in` validation must be added for the dropdown interface to work."
          />
        );

      default:
        return <SingleLineEditor field={sdk.field} locales={sdk.locales} />;
    }
  };

  const handleUniqueEntryOpen = (entryId: string) => {
    sdk.navigator.openEntry(entryId, { slideIn: true });
  };

  return (
    <>
      {renderEditor()}
      {!!uniqueErrorEntries.length && (
        <FormControl.ValidationMessage>
          <Stack
            as="span"
            flexDirection="column"
            alignItems="start"
            style={{ gap: "0.5rem" }}
          >
            {`There's already ${uniqueErrorEntries.length} ${uniqueErrorEntries.length > 1 ? "entries" : "entry"
              } with the same field value. The value has to be unique.`}
            {uniqueErrorEntries.map(({ id, name }) => (
              <b key={id}>
                <TextLink
                  as="button"
                  variant="negative"
                  icon={<ExternalLinkIcon />}
                  alignIcon="end"
                  onClick={() => {
                    handleUniqueEntryOpen(id);
                  }}
                >
                  {name}
                </TextLink>
              </b>
            ))}
          </Stack>
        </FormControl.ValidationMessage>
      )}
      {sizeErrorMessage && (
        <FormControl.ValidationMessage>
          {sizeErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {inErrorMessage && (
        <FormControl.ValidationMessage>
          {inErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {regexpErrorMessage && (
        <FormControl.ValidationMessage>
          {regexpErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {prohibitRegexpErrorMessage && (
        <FormControl.ValidationMessage>
          {prohibitRegexpErrorMessage}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default SymbolField;

/**
 * A symbol field with all possible standard validations looks like:
 *
 * const modelField = {
 *   id: "symbol",
 *   name: "Symbol",
 *   type: "Symbol",
 *   localized: false,
 *   required: false,
 *   disabled: false,
 *   omitted: false,
 *
 *   validations: [
 *     {
 *       unique: true
 *     },
 *     {
 *       size: {
 *         min: 1,
 *         max: 5
 *       }
 *     },
 *     {
 *       regexp: {
 *         pattern: "^a",
 *         // The Contentful UI ends up with flags as null, however only string is
 *         // allowed according to their ContentTypeFieldValidation and RegExp type
 *         flags: null
 *       }
 *     },
 *     {
 *       prohibitRegexp: {
 *         pattern: "^ab",
 *         flags: null
 *       }
 *     },
 *     {
 *       in: [
 *         "abc"
 *       ]
 *     }
 *   ],
 * }
 */
