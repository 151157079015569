import { Radio, Stack, TextLink } from "@contentful/f36-components";
import GeneralWarning from "../GeneralWarning";
import { v4 as uuidv4 } from "uuid";

type Option = string;
type ContentfulRadioSymbolSelectorProps = {
  onSelect: (value: Option) => void;
  options: Option[];
  selectedOption?: Option;
  noOptionsWarning: string;
};

const ContentfulRadioSymbolSelector = ({
  onSelect,
  options,
  selectedOption,
  noOptionsWarning,
}: ContentfulRadioSymbolSelectorProps) => {
  const clearId = `clear-radio-${uuidv4()}`;
  const handleOnChange = (option: Option) => {
    onSelect(option);
  };
  const handleClear = () => {
    onSelect("");
  };

  if (!options?.length) return <GeneralWarning text={noOptionsWarning} />;

  return (
    <Stack flexDirection="column" alignItems="start">
      {options.map((option) => {
        const isChecked = option === selectedOption;
        return (
          <Stack key={option}>
            <Radio
              value={option}
              isChecked={isChecked}
              onChange={() => handleOnChange(option)}
            >
              {option}
            </Radio>
            {isChecked && (
              <TextLink
                aria-labelledby={clearId}
                as="button"
                variant="primary"
                onClick={handleClear}
              >
                <label id={clearId}>Clear</label>
              </TextLink>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ContentfulRadioSymbolSelector;
