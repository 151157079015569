import { Box, Select, TextLink } from "@contentful/f36-components";
import GeneralWarning from "../GeneralWarning";
import { v4 as uuidv4 } from "uuid";
import { ChangeEvent } from "react";

type Option = string;
type ContentfulDropdownSymbolSelectorProps = {
  onSelect: (value: Option) => void;
  options: Option[];
  selectedOption?: Option;
  noOptionsWarning: string;
};

const ContentfulDropdownSymbolSelector = ({
  onSelect,
  options,
  selectedOption,
  noOptionsWarning,
}: ContentfulDropdownSymbolSelectorProps) => {
  const id = `dropdown-${uuidv4()}`;
  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value)
  };

  const handleClear = () => {
    onSelect("");
  };

  if (!options?.length) return <GeneralWarning text={noOptionsWarning} />;

  return (
    <>
      <Select
        id={id}
        name="optionSelect-controlled"
        value={selectedOption}
        onChange={handleOnChange}
      >
        <Select.Option value="">Select one of the options</Select.Option>
        {(options || []).map((option) =>
          <Select.Option key={option} value={option}>{option}</Select.Option>
        )}
      </Select>
      {selectedOption && (
        <Box
          marginTop="spacingS"
        >
          <TextLink
            aria-labelledby={id}
            as="button"
            variant="primary"
            onClick={handleClear}
          >
            <label id={id}>Clear</label>
          </TextLink>
        </Box>
      )}
    </>
  );
};

export default ContentfulDropdownSymbolSelector;
