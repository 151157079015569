import { Checkbox, Stack } from "@contentful/f36-components";
import GeneralWarning from "../GeneralWarning";

type Option = string;
type MultipleCheckboxProps = {
  onSelect: (value: Option) => void;
  options: Option[];
  selectedOptions: Option[];
  noOptionsWarning: string;
};

const MultipleCheckbox = ({
  onSelect,
  options,
  selectedOptions,
  noOptionsWarning,
}: MultipleCheckboxProps) => {
  const handleOnChange = (option: Option) => {
    onSelect(option);
  };

  if (!options?.length) return <GeneralWarning text={noOptionsWarning} />;

  const allOptions = Array.from(new Set([...options, ...selectedOptions]))

  return (
    <Stack flexDirection="column" alignItems="start" spacing="spacingXs">
      {allOptions.map((option) => {
        const isChecked = selectedOptions.includes(option);
        const isNotInOptions = !options.includes(option)

        return (
          <Stack key={option}>
            <Checkbox
              value={option}
              isChecked={isChecked}
              onChange={() => handleOnChange(option)}
              isIndeterminate={isNotInOptions}
            >
              {option}
            </Checkbox>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MultipleCheckbox;
