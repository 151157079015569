import { Flex, Spinner, Text } from "@contentful/f36-components";

const LoadingIndicator = () => (
  <Flex>
    <Text marginRight="spacingXs">Loading</Text>
    <Spinner />
  </Flex>
);

export default LoadingIndicator;
