import { FieldExtensionSDK } from "@contentful/app-sdk";
import { useCallback } from "react";
import { ValidationAction } from "../types";

export const VALIDATOR_FIELD = "conditionalFieldsValidation";

export function useValidatorField({ sdk }: { sdk: FieldExtensionSDK }) {
  return useCallback(
    async (validationUpdates: ValidationAction | null) => {
      if (!sdk.entry.fields[VALIDATOR_FIELD]) return;

      if (validationUpdates === null) {
        await sdk.entry.fields[VALIDATOR_FIELD].setValue({});
        return;
      }

      const validationFieldValue =
        sdk.entry.fields[VALIDATOR_FIELD].getValue() || {};

      for (const key in validationUpdates) {
        if (Object.prototype.hasOwnProperty.call(validationUpdates, key)) {
          const validation = validationUpdates[key];
          const prefixedKey = `${sdk.field.id}.${key}`;

          if (validation) {
            validationFieldValue[prefixedKey] = validation;
          } else {
            delete validationFieldValue[prefixedKey];
          }
        }
      }
      await sdk.entry.fields[VALIDATOR_FIELD].setValue(validationFieldValue);
    },
    [sdk.entry.fields, sdk.field.id]
  );
}
