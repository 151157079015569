import { FieldExtensionSDK } from "@contentful/app-sdk";
import { useEffect, useState } from "react";
import {
  Condition,
  ConditionalFieldMetadata,
  EditorInterfaceWidgetOptions,
  OnDisable,
} from "../types";

export const useCondition = ({
  conditionalFieldMetadata = {},
  sdk,
}: {
  conditionalFieldMetadata?: ConditionalFieldMetadata | null;
  sdk: FieldExtensionSDK;
}) => {
  const [condition, setCondition] = useState<string | Condition>("");
  const [dependentValue, setDependentValue] = useState<string | number>();
  const [baseEditorInterfaceWidget, setBaseEditorInterfaceWidget] =
    useState<EditorInterfaceWidgetOptions>();
    const [onDisable, setOnDisable] = useState<OnDisable>()

  useEffect(() => {
    const result =
      getFieldConditionsAndOtherAttributes({
        conditionalFieldMetadata: conditionalFieldMetadata || {},
        sdk,
      });

    if (typeof result === "string") {
      setCondition(result);
    } else {
      const { fieldConditions, dependentField, baseEditorInterfaceWidget } =
        result;

      setDependentValue(dependentField.getValue());

      const fieldCondition =
        fieldConditions.values[String(dependentValue)] ||
        fieldConditions.default;

      setCondition(
        fieldCondition ||
          `No validations is registered for the ${
            dependentField.id
          } field with a value of ${JSON.stringify(dependentValue)}`
      );

      setBaseEditorInterfaceWidget(baseEditorInterfaceWidget);

      setOnDisable(fieldConditions.onDisable)

      return dependentField.onValueChanged(setDependentValue);
    }
  }, [conditionalFieldMetadata, dependentValue, sdk]);

  return { condition, baseEditorInterfaceWidget, onDisable };
};

const getFieldConditionsAndOtherAttributes = ({
  conditionalFieldMetadata,
  sdk,
}: {
  conditionalFieldMetadata: ConditionalFieldMetadata;
  sdk: FieldExtensionSDK;
}) => {
  const contentTypeConditions =
    conditionalFieldMetadata[sdk.contentType.sys.id];

  if (!contentTypeConditions)
    return "No registered conditions for this contentType";

  const conditionForField = contentTypeConditions[sdk.field.id];

  if (!conditionForField) return "No registered conditions for this field";

  const { baseEditorInterfaceWidget, ...fieldConditions } = conditionForField;

  const dependentField = sdk.entry.fields[fieldConditions.dependentFieldId];

  if (!dependentField)
    return `Conditions are registered, but for a field that does not exist: ${fieldConditions.dependentFieldId}`;

  return { fieldConditions, dependentField, baseEditorInterfaceWidget };
};
