import { BooleanEditor } from "@contentful/field-editor-boolean";
// import { useCallback, useEffect, useState } from "react";
import { EditorProps } from "../../types";

type Params = Parameters<typeof BooleanEditor>[0]["parameters"];

const BooleanField = ({
  sdk,
  params,
  // handleValidationUpdate,
}: EditorProps) => {
  // HT - uncomment and use to implement when "required" validation is added

  // const [existingFieldValue, setExistingFieldValue] = useState<boolean>();

  // useEffect(() => {
  //   sdk.field.onValueChanged(setExistingFieldValue);
  // }, [sdk.field]);

  // const validate = useCallback(async () => {
  //   sdk.field.setInvalid();

  //   if (handleValidationUpdate) handleValidationUpdate({});
  // }, [sdk.field, handleValidationUpdate]);

  // useEffect(() => {
  //   validate();
  // }, [validate]);

  const parameters = {
    instance: {
      trueLabel: params?.trueLabel,
      falseLabel: params?.falseLabel,
    },
  } as Params;

  return (
    <>
      <BooleanEditor field={sdk.field} parameters={parameters} />
    </>
  );
};

export default BooleanField;

/**
 * A Boolean field with all possible standard validations looks like (only required):
 *
 * const modelField = {
 *   id: "Boolean",
 *   name: "Boolean",
 *   type: "Boolean",
 *   localized: false,
 *   required: false,
 *   disabled: false,
 *   omitted: false,
 * }
 */
