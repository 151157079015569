import React, { useCallback, useState, useEffect } from "react";
import { AppExtensionSDK } from "@contentful/app-sdk";
import {
  Button,
  Flex,
  FormControl,
  Textarea,
} from "@contentful/f36-components";
import { css } from "emotion";
import { useSDK } from "@contentful/react-apps-toolkit";
import { ConditionalFieldMetadata } from "../types";
import { ExampleCodeBlock } from "../components/ExampleCodeBlock";

export interface AppInstallationParameters {
  conditionalFieldMetadata: ConditionalFieldMetadata;
}

const DEFAULT_PARAMETERS = {
  conditionalFieldMetadata: {},
} as const;

const ConfigScreen = () => {
  const [parameters, setParameters] =
    useState<AppInstallationParameters>(DEFAULT_PARAMETERS);
  const [conditionalFieldMetadata, setConditionalFieldMetadata] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [validationMessage, setValidationMessage] = useState("");
  const sdk = useSDK<AppExtensionSDK>();

  const onConfigure = useCallback(async () => {
    // This method will be called when a user clicks on "Install"
    // or "Save" in the configuration screen.
    // for more details see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#register-an-app-configuration-hook

    // Get current the state of EditorInterface and other entities
    // related to this app installation
    const currentState = await sdk.app.getCurrentState();

    return {
      // Parameters to be persisted as the app configuration.
      parameters,
      // In case you don't want to submit any update to app
      // locations, you can just pass the currentState as is
      targetState: currentState,
    };
  }, [parameters, sdk]);

  useEffect(() => {
    // `onConfigure` allows to configure a callback to be
    // invoked when a user attempts to install the app or update
    // its configuration.
    sdk.app.onConfigure(() => onConfigure());
  }, [sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      const currentParameters: AppInstallationParameters | null =
        (await sdk.app.getParameters()) || DEFAULT_PARAMETERS;

      setParameters(currentParameters);

      const currentConditionalFieldMetadataString = formatValue(
        currentParameters?.conditionalFieldMetadata
      );
      setConditionalFieldMetadata(currentConditionalFieldMetadataString);

      const { valid, errorMessage } = validateStringValue(
        currentConditionalFieldMetadataString
      );

      setIsValid(valid);
      setValidationMessage(errorMessage);

      sdk.app.setReady();
    })();
  }, [sdk]);

  const handleFormat = () => {
    try {
      const parsed = JSON.parse(conditionalFieldMetadata);
      const formatted = formatValue(parsed);
      setConditionalFieldMetadata(formatted);
    } catch (error) {}
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setConditionalFieldMetadata(e.target.value);

    const { valid, errorMessage } = validateStringValue(e.target.value);
    setIsValid(valid);
    setValidationMessage(errorMessage);
    if (valid) {
      setParameters({
        conditionalFieldMetadata: JSON.parse(e.target.value),
      });
    }
  };

  return (
    <Flex
      flexDirection="column"
      className={css({ margin: "80px", maxWidth: "800px" })}
      gap="20px"
    >
      <Button variant="secondary" isDisabled={!isValid} onClick={handleFormat}>
        Format
      </Button>
      <FormControl isInvalid={!isValid}>
        <FormControl.Label>Conditional Field Metadata</FormControl.Label>
        <Textarea
          style={{
            fontFamily: "monospace",
            height: "70vh",
          }}
          value={conditionalFieldMetadata}
          placeholder="Enter your Conditional Field Metadata configuration..."
          onChange={handleChange}
        />
        {!isValid && (
          <FormControl.ValidationMessage>
            Invalid JSON: {validationMessage}
          </FormControl.ValidationMessage>
        )}
        <FormControl.HelpText>
          Enter your conditional field metadata. It must conform to the
          following type:
          <ExampleCodeBlock />
        </FormControl.HelpText>
      </FormControl>
    </Flex>
  );
};

export default ConfigScreen;

const validateStringValue = (value: string) => {
  try {
    const parsed = JSON.parse(value);
    const stringified = JSON.stringify(parsed);
    const stripped = value.replaceAll(/\s/g, "");
    return {
      valid: stripped === stringified,
      errorMessage: "",
    };
  } catch (error: any) {
    return {
      valid: false,
      errorMessage: (error?.message as string) || "Unknown error",
    };
  }
};

const formatValue = (value: any) => JSON.stringify(value, null, 2);
