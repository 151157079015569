import { FormControl } from "@contentful/f36-components";
import { SysLink } from "contentful-management";
import { useCallback, useEffect, useState } from "react";
import { EditorProps } from "../../types";
import ContentfulMultipleEntryReferenceEditor from "./ContentfulMultipleEntryReferenceEditor";

const ArrayEntryField = ({
  validations,
  itemValidations,
  params,
  sdk,
  clients,
  handleValidationUpdate,
}: EditorProps) => {
  const { max, min } =
    validations?.find((validation) => !!validation.size)?.size || {};

  const checkIsFull = useCallback(
    (fieldValue: SysLink[]) => {
      return max !== undefined && fieldValue.length >= max;
    },
    [max]
  );

  const [sizeErrorMessage, setSizeErrorMessage] = useState("");
  const [isFull, setIsFull] = useState(checkIsFull(sdk.field.getValue() || []));

  const contentTypeIds = itemValidations?.find(
    (validation) => !!validation.linkContentType
  )?.linkContentType;

  const validateSize = useCallback((): boolean => {
    const existingFieldValue = sdk.field.getValue() || [];

    if (min !== undefined && existingFieldValue.length < min) {
      setSizeErrorMessage(
        `The field must have at least ${min} item${min > 1 ? "s" : ""}.`
      );
      return true;
    } else if (max !== undefined && existingFieldValue.length > max) {
      setSizeErrorMessage(
        `The field must have at most ${max} item${max > 1 ? "s" : ""}.`
      );
      return true;
    }

    setSizeErrorMessage("");
    return false;
  }, [max, min, sdk.field]);

  const validateItems = useCallback(async (): Promise<boolean> => {
    const existingFieldValue: SysLink[] = sdk.field.getValue() || [];

    const entries = await clients.environment.getEntries({
      "sys.id[in]": existingFieldValue.map((link) => link.sys.id).join(),
    });

    const invalidContentTypeIds = entries?.items
      .filter(
        (entry) =>
          contentTypeIds &&
          !contentTypeIds.includes(entry.sys.contentType.sys.id)
      )
      .map((contentType) => contentType.sys.contentType.sys.id);

    return !!invalidContentTypeIds?.length;
  }, [clients.environment, contentTypeIds, sdk.field]);

  const validate = useCallback(async () => {
    const sizeInvalid = validateSize();
    const itemsInvalid = await validateItems();

    sdk.field.setInvalid(sizeInvalid || itemsInvalid);

    if (handleValidationUpdate)
      handleValidationUpdate({
        sizeInvalid,
        itemsInvalid,
      });
  }, [handleValidationUpdate, sdk.field, validateItems, validateSize]);

  useEffect(() => {
    validate();
  }, [validateSize, validateItems, validate]);

  useEffect(
    () =>
      sdk.field.onValueChanged((fieldValue = []) => {
        setIsFull(checkIsFull(fieldValue));
      }),
    [checkIsFull, sdk.field]
  );

  const handleSelectEntries = useCallback(
    async (entryIds: string[]) => {
      const existingFieldValue = sdk.field.getValue() || [];

      await sdk.field.setValue([
        ...existingFieldValue,
        ...entryIds.map(
          (entryId): SysLink => ({
            sys: {
              type: "Link",
              linkType: "Entry",
              id: entryId,
            },
          })
        ),
      ]);
    },
    [sdk.field]
  );

  return (
    <>
      <ContentfulMultipleEntryReferenceEditor
        sdk={sdk}
        isFull={isFull}
        contentTypeIds={contentTypeIds}
        onAction={validate}
        onSelectEntries={handleSelectEntries}
        {...params}
      />
      {sizeErrorMessage && (
        <FormControl.ValidationMessage>
          {sizeErrorMessage}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default ArrayEntryField;

/**
 * A entry array field with all possible standard validations looks like:
 *
 * const modelField = {
 *   id: "manyReferences",
 *   name: "Many references",
 *   type: "Array",
 *   localized: false,
 *   required: false,
 *   disabled: false,
 *   omitted: false,
 *
 *   validations: [
 *     {
 *       size: {
 *         min: 1,
 *         max: 2,
 *       },
 *       message: "entry limit error",
 *     },
 *   ],
 *   items: {
 *     type: "Link",
 *     linkType: "Entry",
 *
 *     validations: [
 *       {
 *         linkContentType: ["listItem"],
 *         message: "specified entry type error",
 *       },
 *     ],
 *   },
 * };
 */
